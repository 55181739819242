import * as Vue from 'vue';
import api from './api';

import dayjs from 'dayjs';
window.dayjs=dayjs;

// console.log(App);
var components={}
for(let vueComponentListItem of [
  require.context(".", false, /.+\.vue$/),
])
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    components[actualKey]=actualComponent;
  }

for(let node of document.querySelectorAll("script").entries())
  node[1].parentNode.removeChild(node[1]);

Vue.createApp({
  components,
  mounted() {
    window.appRoot=this;
  },
}).mount('body');


